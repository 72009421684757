import React from 'react'
import { Helmet } from 'react-helmet'
import LandscapeScene from '../components/LandscapeScene'
import Step from '../components/Step'
import styles from './chat-not-found.module.css'
import { pushBodyClassname } from '../lib/pushClassname'

const InfoText = ({ children }) => (
  <p className={styles.infoText}>
    {children}
  </p>
)

const ChatNotFound = ({ message }) => {
  React.useEffect(pushBodyClassname('default-cursor'), [])

  return (
    <div className={styles.page}>
      <Helmet>
        <base target="_parent" />
      </Helmet>
      <LandscapeScene>
        <section className={styles.messageSection}>
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
              maxWidth: 375,
              width: '100%',
              justifyContent: 'space-between',
              padding: 25,
            }}
          >
            <InfoText>
              {message}
            </InfoText>
            <InfoText>
              In the meantime...
            </InfoText>
          </div>
          <Step
            title="Download Muze"
            href="https://apps.apple.com/app/id1439964290"
            color="#9D8EF5"
          />
        </section>
      </LandscapeScene>
    </div>
  )
}

ChatNotFound.defaultProps = {
  message: "This is not a valid invite link :(\nAsk for the link to be resent."
}

export default ChatNotFound
